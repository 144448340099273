@import "grid";
@import "colors";

//color definitions:
$font-size: 16px;
$previewWidth: 275px;

@font-face {
  font-family: 'Proxima Nova';
  //src: url('/fonts/proximanova-bold-webfont.eot');
  //src: url('/fonts/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),url('/fonts/proximanova-bold-webfont.woff') format('woff'),url('/fonts/proximanova-bold-webfont.ttf') format('truetype'),url('/fonts/proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
  font-weight: bold;
  font-style: normal
}

@font-face {
  font-family: 'Proxima Nova';
  //src: url('/fonts/proximanova-regular-webfont.eot');
  //src: url('/fonts/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),url('/fonts/proximanova-regular-webfont.woff') format('woff'),url('/fonts/proximanova-regular-webfont.ttf') format('truetype'),url('/fonts/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
  font-weight: normal;
  font-style: normal
}

* {
  box-sizing: border-box;
}


body {
  font-size: $font-size;
  margin: 0;
  font-family: "Proxima Nova", Helvetica, Arial, sans-serif;

  // -webkit-user-select: none;  /* Chrome all / Safari all */
  // -moz-user-select: none;     /* Firefox all */
  // -ms-user-select: none;      /* IE 10+ */
  // user-select: none;          /* Likely future */

  overflow-y: scroll;

  #root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
}

button {
  padding: 6px 20px;
  cursor: pointer;

  background: white;
  border: 1px solid $color-blue-selected;
  box-shadow: 0 2px 1px 0 $color-blue-dropshadow-active;

  border-radius: 3px;

  &:hover {
    background: darken($color-blue-hover, 5%);
  }
}

h1 {
  font-size: $font-size * 3;
}

h3 {
  margin-bottom: 0;
}

p {
  line-height: 1.5em;
}

a {
  color: $link-color;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

header {
  background: $color-rouge;
  border-bottom: 2px solid $color-deep-red;
  padding: 13px 14px 8px 14px;

  color: $color-pale-grey;
  font-size: 22px;

  .home-link {
    float: right;

    &.selected {
      display: none;
    }
  }

  img {
    height: 24px;
    margin-right: 1ex;
  }
}

main {
  flex: 1;

  max-width: 100rem;
  width: 100%;
  margin: auto;

  box-sizing: border-box;
  padding: 1em;

  & > section {
    box-sizing: border-box;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1em;

    &.text-block {
      & > * {
        max-width: 1080px * calc(2 / 3);
      }
    }
  }

  .withPreview {
    min-height: ($previewWidth - 3px) * calc(3 / 4);
  }
}

.container {
  max-width: 80rem;
  box-sizing: border-box;
  margin: 0 auto;
}

footer {
  background: $color-pale-grey;
  color: $color-charcoal-grey;

  .container {
    padding: 0.5ex;
  }

  .sloganColumn {
    @include col((phone: (1, 1), tablet: (2, 3)));
  }

  .contactColumn {
    @include col((phone: (1, 1), tablet: (1, 3)));
  }

  h4 {
    margin-top: 0;
    margin-bottom: 0;
  }

  p {
    margin-top: 0;

    img {
      height: $font-size * 2.5;
      margin-right: $font-size * 1.1;
    }
  }

  .subtitle {
    margin-top: 0;
    display: block;
  }

  .telephone {
    font-size: $font-size * 1.2;
    margin: 7px 0;
    font-weight: bold;
    display: block;
    line-height: $font-size * 1.5;

    a {
      color: $color-charcoal-grey;
      text-decoration: none;
    }
  }
}

.print-only {
  display: none;
}

@media print {
  .directions {
    display: none;
  }

  .button {
    display: none;
  }

  .no-print {
    display: none;
  }

  .print-only {
    display: initial;
  }
}

hgroup.stepHeader {
  h2 {
    color: $color-rouge;
    margin-bottom: 0;
  }

  h3 {
    margin-top: 0;
  }
}

hgroup.intro {
  color: white;
  height: 230px;
  background: center center no-repeat;
  background-size: cover;
  text-shadow: 1px 1px 4px $color-charcoal-grey;

  .container {
    height: 100%;
    position: relative;
    padding: 0 1em;

    .atBottom {
      position: absolute;
      bottom: $font-size;
    }
  }

  h1, h2 {
    margin: 0;
  }

  h2 {
    font-weight: 600;
  }
}

@media only screen and (max-width: map-get($map: $breakpoints, $key: 'tablet')) {
  hgroup.intro {
    height: 112px;

    h1 {
      font-size: $font-size * 1.5;
    }

    h2 {
      display: none;
    }
  }

  section.withPreview {
    padding-right: $previewWidth * calc(3 / 4) !important;

    .preview {
      width: $previewWidth * calc(3 / 4) - 3px !important;

      canvas {
        width: $previewWidth * calc(3 / 4) - 3px !important;
        height: ($previewWidth  * calc(3 / 4) - 3px) * calc(3 / 4) !important;
      }
    }
  }
}


$masterdetailmaster: (phone: (1, 3, 0.1), tablet: (1, 4, 0.1), screen: (1, 5, 0, 0));
$masterdetaildetail: (phone: (2, 3, 0.1), tablet: (3, 4, 0.1), screen: (4, 5, 0, 0));

.masterdetail {
  @include row($masterdetailmaster);

  .master {
    @include col($masterdetailmaster);
    box-sizing: border-box;
    padding: 1em;
  }

  .detail {
    @include col($masterdetaildetail);
    box-sizing: border-box;
    padding: 1em;
  }
}

.formgroup {
  label {
    font-weight: bold;
    display: inline-block;
    margin: 0 1ex;
    width: 8em;

    &.short {
      width: auto;
    }
  }
}


.panel {
  border: 1px solid $color-blue-selected;
  border-radius: 3px;

  margin-bottom: 1em;

  h3 {
    margin: 0;
    padding: 5px;
    border-bottom: 1px solid $color-blue-selected;
    background: $color-blue-hover;
  }

  h4:first-child {
    margin: 0 0 1ex;
  }

  .content {
    padding: 1em;
  }
}


table {
  margin: auto;
  width: 100%;
  font-size: 13px;

  border-collapse: collapse;

  &.doublerowtable {
    th {
      background: #ddd;
    }

    tr:nth-child(4n+3), tr:nth-child(4n) {
      td {
        background: #ddd;
      }
    }
  }

  td, th {
    padding: 0.8rem 1em;
    text-align: left;
    vertical-align: center;
    min-width: 130px;
    word-break: break-all;

    &.center {
      text-align: center;
    }
  }
}


.tabs {
  display: flex;
  flex-wrap: wrap;

  hr {
    order: 2;
    flex-grow: 1;
    width: 100%;
    margin: 0 0 1em;
    border: 1px solid $color-blue-selected;
  }

  .tab-switch {
    display: none;
  }

  .tab-switch + label {
    background: white;
    padding: 0.9em;
    order: 1;
    cursor: pointer;
  }

  .tab-switch:checked + label {
    background: $color-blue-selected;
    color: #fff;
    z-index: 2;
  }

  .tab-content {
    order: 99;
    flex-grow: 1;
    width: 100%;


    height: 0;
    overflow: visible;
    opacity: 0;
    list-style: none;
    margin: 0;
    padding: 0 .5ex;
    transition: all 1s;
  }

  .tab-switch:checked + label + .tab-content {
    height: auto;
    opacity: 1;
  }
}
