@import "grid";
@import "colors";

$parties: (phone: (1, 1), tablet: (1, 4));
.purchaseOrderDetails {
  .withError td {
    background-color: rgba($color-deep-red, 0.2);
  }

  .removeIcon:hover {
    cursor: pointer;
  }

  .intInput {
    width: 6em;
  }

  .decimalInput {
    width: 6em;
  }

  .alreadySentLabel {
    float: right;
  }

  table.orderProductsTable {
    td {
      vertical-align: middle;
    }
  }

  .backLink {
    float: right;
  }

  h2 {
    margin-bottom: 0;
  }

  .parties {
    @include row($parties);
    padding: 0;
    margin: 0 -1ex;

    .party {
      @include col($parties);

      box-sizing: border-box;
      padding: 1ex;
    }
  }

  th.money, td.money {
    text-align: right;
    width: 1px;
  }

  td.total {
    border-top: 1px solid black;
  }

  th.amount, td.amount {
    text-align: left;
    width: 1px;
    white-space: nowrap;
  }

  th.articleNumber, td.articleNumber {
    text-align: left;
    overflow-wrap: break-word;
    min-width: 250px;
  }


  th.trash, td.trash {
    max-width: 38px;
    min-width: 38px;
    padding-left: 10px;
  }

  th.product {
    width: 400px;
  }

  .actionBar {
    margin-top: 2em;
    border-top: 1px solid rgba(0.5, 0.5, 0.5, 0.5);
    text-align: right;

    button {
      margin-top: 1ex;
    }

  }
}
