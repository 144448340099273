.header-bg {
    background-color: #ba1e2b;
}

.navbar {
    background-color: #ba1e2b;
}

.navbar-item {
    border-right: 1px solid #B3B3B3;
}
