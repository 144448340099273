.createNewLink {
  float: right;
}

.orderoverview {
  dl {
    margin: 0 1ex 1ex 2em;
  }

  dt {
    font-weight: bold;
    float: left;
    width: 12em;
    white-space: nowrap;
  }
}

.search_and_select {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.searchBar {
  align-self: center;
  margin-top: 10px;
}
