@use 'sass:math';

//min-widths:
$breakpoints: (
        tablet: 768px,
        screen: 992px,
        huge: 1200px
);

@mixin row($columnDefs, $gap: 1ex) {
  padding: math.div($gap, 2);

  @if map-has-key($columnDefs, phone) {
    $item: map-get($columnDefs, phone);

    @if length($item) > 2 {
      $f2: nth($item, 2);
      $f3: nth($item, 3);
      $f4: nth($item, 3);
      @if length($item) > 3 {
        $f4: nth($item, 4);
      }

      margin: 0 math.div(100% * $f4, ($f2 + $f3 + $f4)) 0 math.div(100% * $f3, ($f2 + $f3 + $f4));
    }
  }

  @each $breakpoint, $v in $breakpoints {
    @if map-has-key($columnDefs, $breakpoint) {
      @media only screen and (min-width: $v) {
        $item: map-get($columnDefs, $breakpoint);
        @if length($item) > 2 {
          $f2: nth($item, 2);
          $f3: nth($item, 3);
          $f4: nth($item, 3);
          @if length($item) > 3 {
            $f4: nth($item, 4);
          }

          margin: 0 math.div(100% * $f4, ($f2 + $f3 + $f4)) 0 math.div(100% * $f3, ($f2 + $f3 + $f4));
        }
      }
    }
  }
}

@mixin col($columnDefs, $gap: 1ex, $align: top) {
  display: inline-block;
  box-sizing: border-box;
  padding: math.div($gap, 2);
  vertical-align: $align;

  @if map-has-key($columnDefs, phone) {
    $item: map-get($columnDefs, phone);
    width: math.div(100% * nth($item, 1), nth($item, 2));

  } @else {
    width: 100%;
  }

  @each $breakpoint, $v in $breakpoints {
    @if map-has-key($columnDefs, $breakpoint) {
      @media only screen and (min-width: $v) {
        $item: map-get($columnDefs, $breakpoint);

        $col: nth($item, 1);
        $sum: nth($item, 2);

        width: math.div($col, $sum) * 100%;
      }
    }
  }
}
